import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import React from 'react';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Popover.css';

export type RootProps = PopoverPrimitive.PopoverProps;
export type ContentProps = PopoverPrimitive.PopoverContentProps & {
  hideCloseButton?: boolean;
  variant?: 'default' | 'noPadding';
};

export const { Root, Trigger } = PopoverPrimitive;

export const Content = React.forwardRef<HTMLDivElement, ContentProps>(
  ({ children, variant, ...contentProps }: ContentProps, forwardedRef) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content {...contentProps} ref={forwardedRef} className={style.content({ variant })}>
        <div className={style.contentLayout}>
          {children}
          {!contentProps.hideCloseButton && (
            <PopoverPrimitive.Close className={style.close}>
              <AccessibleIcon.Root label="Close popover">
                <Icons.Close />
              </AccessibleIcon.Root>
            </PopoverPrimitive.Close>
          )}
        </div>
        <PopoverPrimitive.Arrow
          width={14}
          height={7}
          fill="currentColor"
          stroke={style.borderColor}
          className={style.arrow}
          asChild
        >
          <svg viewBox="0 0 30 10" preserveAspectRatio="none">
            <polygon points="0,0 30,0 15,10" strokeWidth="0" />
            <polyline points="0,0 15,10 30,0" strokeWidth="1.5" />
          </svg>
        </PopoverPrimitive.Arrow>
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  ),
);
