import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { Sprinkles } from '@mutiny-pkg/dumpster-ui/theme/sprinkles.css';
import { vars } from '@mutiny-pkg/dumpster-ui/theme/theme.css';
import { Box } from '../Box';
import * as style from './Typography.css';

type TypographySprinklesProps = Pick<Sprinkles, 'font' | 'fontWeight' | 'margin' | 'whiteSpace'>;
type ElementTags = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span';

export type TypographyProps = {
  truncated?: boolean;
  capitalize?: boolean;
  wrapText?: boolean;
} & TypographySprinklesProps &
  Omit<React.HTMLAttributes<HTMLElement>, keyof NonNullable<Sprinkles> | 'style'> & {
    as?: ElementTags;
    color?: keyof typeof vars.color;
    style?: Omit<CSSProperties, 'font' | 'fontSize' | 'fontWeight' | 'lineHeight' | 'fontFamily' | 'whiteSpace'>;
  };

export const typographyMap: Record<NonNullable<Pick<TypographySprinklesProps, 'font'>['font']>, ElementTags> = {
  heading1: 'h1',
  heading2: 'h2',
  heading3: 'h3',
  body1: 'p',
  caption1: 'span',
  caption2: 'span',
  metricData1: 'span',
  metricData2: 'span',
  metricData3: 'span',
  metricData4: 'span',
};

export const Typography = ({
  font = 'body1',
  as,
  children,
  margin,
  truncated = false,
  capitalize = false,
  wrapText = false,
  className,
  ...props
}: TypographyProps) => {
  return (
    <Box
      as={as || typographyMap[font]}
      font={font}
      margin={margin}
      className={cx(className, style.self({ truncated, capitalize, wrapText }))}
      lang={wrapText ? 'en' : undefined}
      {...props}
    >
      {children}
    </Box>
  );
};
