import React, { forwardRef } from 'react';
import cx from 'classnames';
import * as TogglePrimitive from '@radix-ui/react-toggle-group';
import * as style from './ToggleGroup.css';

export type ToggleGroupProps = Omit<TogglePrimitive.ToggleGroupSingleProps, 'type'> & {
  fullWidth?: boolean;
};

export type ToggleGroupItemProps = TogglePrimitive.ToggleGroupItemProps & {
  variant?: 'label' | 'icon';
  fullWidth?: boolean;
};

const togglePrimitiveEventHandlers = {
  onMouseDown: (e: React.MouseEvent) => e.preventDefault(),
};

export const ToggleGroup = ({ orientation = 'horizontal', children, fullWidth, ...props }: ToggleGroupProps) => {
  return (
    <TogglePrimitive.Root
      {...props}
      type="single"
      className={cx(style.toggleGroup[orientation], { [style.fullWidth]: fullWidth })}
    >
      {children}
    </TogglePrimitive.Root>
  );
};

export const ToggleGroupItem = forwardRef(function ToggleGroupItem(
  { children, variant = 'label', fullWidth, ...props }: ToggleGroupItemProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <TogglePrimitive.Item
      ref={ref}
      {...togglePrimitiveEventHandlers}
      {...props}
      className={cx(style.toggleItem[variant], { [style.fullWidth]: fullWidth })}
    >
      {children}
    </TogglePrimitive.Item>
  );
});
