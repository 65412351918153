import React from 'react';
import * as PrimitiveDialog from '@radix-ui/react-dialog';
import { Icons } from '@mutiny-pkg/dumpster-ui/icons';
import * as style from './Flyout.css';
import { IconButton } from '../Button';

export const FlyoutTrigger = ({ children, ...props }: PrimitiveDialog.DialogTriggerProps): JSX.Element => (
  <PrimitiveDialog.Trigger {...props} asChild>
    {children}
  </PrimitiveDialog.Trigger>
);

export const FlyoutHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className={style.header}>
      <div>{children}</div>
      <PrimitiveDialog.Close asChild>
        <IconButton variant="modalClose" size="small" icon={Icons.Close} label="close" aria-label="close" />
      </PrimitiveDialog.Close>
    </div>
  );
};
export const FlyoutBody = ({ children }: { children: React.ReactNode }) => {
  return <div className={style.body}>{children}</div>;
};

type Props = {
  children?: React.ReactNode;
};

export const FlyoutContainer = ({ children }: Props) => {
  return (
    <PrimitiveDialog.Portal>
      <PrimitiveDialog.Overlay data-testid="overlay-test" className={style.flyoutOverlay} />;
      <PrimitiveDialog.Content className={style.flyoutContentBase}>{children}</PrimitiveDialog.Content>
    </PrimitiveDialog.Portal>
  );
};

export const Flyout = ({ children, ...props }: PrimitiveDialog.DialogProps): JSX.Element => (
  <PrimitiveDialog.Root {...props}>{children}</PrimitiveDialog.Root>
);
