import React, { CSSProperties } from 'react';
import { Sprinkles } from '@mutiny-pkg/dumpster-ui/theme/sprinkles.css';
import { vars } from '@mutiny-pkg/dumpster-ui/theme/theme.css';
import { Box } from '../Box';
import * as style from './Flex.css';

type FlexContainerSprinklesProps = Pick<
  Sprinkles,
  | 'flex'
  | 'alignItems'
  | 'justifyContent'
  | 'flexDirection'
  | 'placeItems'
  | 'flexWrap'
  | 'gap'
  | 'flexBasis'
  | 'padding'
  | 'paddingX'
  | 'paddingY'
  | 'paddingTop'
  | 'paddingBottom'
  | 'paddingLeft'
  | 'paddingRight'
  | 'margin'
  | 'marginX'
  | 'marginY'
  | 'marginTop'
  | 'marginBottom'
  | 'marginLeft'
  | 'marginRight'
  | 'maxWidth'
>;

type ResponsiveProps<Value> = {
  xs?: Value;
  sm?: Value;
  md?: Value;
  lg?: Value;
};

export type FlexBasisSprinkle = keyof typeof vars.flexColumnWidths;
export type FlexTermSprinkle = keyof typeof vars.flexTerms;

type ResponsiveFlexBasisProps = ResponsiveProps<FlexBasisSprinkle>;
type ResponsiveFlexTermProps = ResponsiveProps<FlexTermSprinkle>;

type BaseProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'style' | keyof NonNullable<Sprinkles>>;

type BaseItemProps = BaseProps & {
  style?: CSSProperties;
};

export type FlexLegacyItemProps = BaseItemProps & ResponsiveFlexBasisProps;
export type FlexItemProps = BaseItemProps &
  ResponsiveFlexTermProps & {
    basis?: CSSProperties['flexBasis'];
    grow?: CSSProperties['flexGrow'];
    shrink?: CSSProperties['flexShrink'];
  };

export type FlexContainerProps = FlexContainerSprinklesProps &
  BaseProps & {
    display?: 'flex' | 'inline-flex' | 'grid';
    style?: Omit<CSSProperties, 'display' | 'gap' | 'alignItems' | 'justifyContent' | 'flexWrap'>;
    fullWidth?: boolean;
  };

// Moved out of component and exported for testing purposes
export function flexTermResolver<Value>({ xs, sm, md, lg }: ResponsiveProps<Value>, defaultValue: Value) {
  return {
    xs: xs ?? defaultValue,
    sm: sm ?? xs ?? defaultValue,
    md: md ?? sm ?? xs ?? defaultValue,
    lg: lg ?? md ?? sm ?? xs ?? defaultValue,
  };
}

export const LegacyItem = ({ xs, sm, md, lg, children, ...props }: FlexLegacyItemProps) => {
  const basis = React.useMemo(() => flexTermResolver<FlexBasisSprinkle>({ xs, sm, md, lg }, 'auto'), [xs, sm, md, lg]);

  return (
    <Box className={style.item} flexBasis={basis} maxWidth={basis} {...props}>
      {children}
    </Box>
  );
};

export const Item = ({ xs, sm, md, lg, children, basis, style: styleProp, ...props }: FlexItemProps) => (
  <Box
    className={style.item}
    flex={React.useMemo(() => flexTermResolver<FlexTermSprinkle>({ xs, sm, md, lg }, 0), [xs, sm, md, lg])}
    style={{
      ...styleProp,
      flexBasis: basis,
    }}
    {...props}
  >
    {children}
  </Box>
);

export const Container = ({
  display = 'flex',
  flexDirection = 'row',
  flexWrap = 'nowrap',
  fullWidth = false,
  children,
  ...props
}: FlexContainerProps) => {
  return (
    <Box
      className={style.containerStyle({ fullWidth })}
      display={display}
      flexDirection={flexDirection}
      flexWrap={flexWrap}
      {...props}
    >
      {children}
    </Box>
  );
};
