import React from 'react';
import * as PrimitiveTabs from '@radix-ui/react-tabs';
import * as styles from './Tabs.css';

export type RootProps = PrimitiveTabs.TabsProps;
export type ListProps = PrimitiveTabs.TabsListProps & {
  alignment?: 'start' | 'center' | 'end';
  label?: string;
};
export type TabProps = PrimitiveTabs.TabsTriggerProps;
export type ContentProps = PrimitiveTabs.TabsContentProps;

type Orientation = RootProps['orientation'];
const TabsOrientationContext = React.createContext<Orientation>(undefined);

export const Root = ({ children, orientation = 'horizontal', ...props }: RootProps) => {
  return (
    <PrimitiveTabs.Root className={styles.root({ orientation })} orientation={orientation} {...props}>
      <TabsOrientationContext.Provider value={orientation}>{children}</TabsOrientationContext.Provider>
    </PrimitiveTabs.Root>
  );
};

export const List = ({ children, label, alignment, ...props }: ListProps) => {
  const orientation = React.useContext(TabsOrientationContext);
  return (
    <PrimitiveTabs.List
      className={styles.list({ orientation })}
      style={{ justifyContent: alignment }}
      aria-label={label}
      {...props}
    >
      {children}
    </PrimitiveTabs.List>
  );
};

export const Tab = ({ children, ...props }: TabProps) => {
  const orientation = React.useContext(TabsOrientationContext);
  return (
    <PrimitiveTabs.TabsTrigger className={styles.tab({ orientation })} {...props}>
      {children}
    </PrimitiveTabs.TabsTrigger>
  );
};

export const Content = ({ children, ...props }: ContentProps) => {
  return <PrimitiveTabs.Content {...props}>{children}</PrimitiveTabs.Content>;
};
